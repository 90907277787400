<template>
  <li class="form_li">
    <div class="form_in">
      <div class="file_box">
        <label for="file_img"
          ><img
            src="@/assets/static/images/i_photo.svg"
            alt="icon"
          />사진첨부</label
        >
        <input
          type="file"
          id="file_img"
          :multiple="multiple"
          class="custom-file"
          :accept="accepts"
          v-bind="$attrs"
          v-on="listeners"
        />
      </div>
    </div>
    <div class="hint_b on">
      <p>이미지 (JPG, PNG) 기준 최대 3개 등록 가능합니다.</p>
    </div>
    <div class="file_selected" v-for="(item, index) in filePaths" :key="index">
      <!--첫번째 아이 first 클래스 부여-->
      <div class="file_name">
        <img
          :src="
            $Util.isEmpty(item.imagePath)
              ? imageUrl[index]
              : $ConstCode.getImagePath(item.imagePath, '480/')
          "
          alt=""
        />
        <span class="name">{{ item.fileName }}</span>
      </div>

      <button
        class="btn-delete"
        @click="deleteImage(index)"
        style="display: contents"
      >
        <div class="cancel"></div>
      </button>
    </div>
  </li>
</template>

<script>
import { reactive, computed, toRefs, getCurrentInstance, watch } from 'vue'

export default {
  name: 'ImgInput',
  description: '',
  props: {
    fileUrl: {
      type: String
    },
    filePaths: {
      type: [Object, Array],
      default: () => {}
    },
    bindData: {
      type: Array,
      default: () => []
    },
    multiple: Boolean,
    maxCount: {
      type: Number,
      default: null
    },
    accepts: {
      type: [String, Boolean],
      default: 'image/*'
    }
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      files: [],
      paramData: [],
      bindData: []
    })

    const imageUrl = computed(() => {
      const files = state.files
      const result = []
      let url = props.fileUrl
      for (const file of files) {
        if (file) {
          if (file.type.indexOf('image/') > -1) {
            url = URL.createObjectURL(file)
            result.push(url)
          }
        }
      }
      return result
    })

    watch(props, () => {
      state.paramData = props.bindData
    })

    const fileChange = event => {
      state.files = event.target.files
      emit('file-change', state.files)
    }

    const listeners = computed(() => {
      return {
        ...proxy.$listeners,
        change: fileChange
      }
    })
    const deleteImage = index => {
      emit('delete-image', index)
    }

    return {
      listeners,
      ...toRefs(state),
      deleteImage,
      imageUrl
    }
  }
}
</script>

<style scoped></style>
